import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    isPhone: null
  },
  getters: {

  },
  modules: {
  },
  mutations: {
  }
})