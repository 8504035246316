import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)


export default new Router({
  // mode: 'history',
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     const position = {}
  //     if (to.hash) {
  //       position.selector = to.hash
  //     }
  //     if (to.matched.some(m => m.meta.scrollToTop)) {
  //       position.x = 0
  //       position.y = 0
  //     }
  //     if (to.query.y > 0) {
  //       position.x = 0
  //       position.y = to.query.y
  //     }
  //     return position
  //   }
  // },
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: "/home",
      component: () => import('@/views/home/index.vue'),
    },
    {
      path: "/pro",
      component: () => import('@/views/pro/index.vue'),
    },
    {
      path: "/production-info",
      component: () => import('@/views/detail/production-info.vue'),
    },
    {
      path: "/production",
      component: () => import('@/views/detail/production.vue'),
    },
    {
      path: "/industry",
      component: () => import('@/views/industry/index.vue'),
    },
    {
      path: "/cloud",
      component: () => import('@/views/cloud/index.vue'),
    },
    {
      path: "/solution",
      component: () => import('@/views/detail/solution-1.vue'),
    },
    {
      path: "/about",
      component: () => import('@/views/about/index.vue'),
    },
  ]
})