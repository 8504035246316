<template>
  <div>

    <div class="footer">
      <div
        class="email"
        v-if="!$store.state.isPhone"
      >
        <el-row>
          <el-col :span="12">
            <div class="title">
              方案咨询
            </div>
            <div class="desc">
              关于产品和解决方案的更多资讯， 请联系我们的销售和技术支持团队。
            </div>
          </el-col>
          <el-col :span="12">
            <div class="input">
              <el-input
                v-model="input3"
                size="mini"
                placeholder="请输入内容"
                style="width:315px;border:none"
                @focus="handleIn"
              ></el-input>
              <!-- <a
                :href="`mailto:mail@cisinfo.cn?subject=test&cc=mail@cisinfo.cn&subject=方案咨询&body=${input3}`"
                style="background: #0D13D7;color:#fff;padding:8px 22px;font-size:0.825em;border-radius:15px"
              >发送</a> -->
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="footer-top clearfix">
        <div class="footer-content container-custom">
          <el-row :gutter="25">
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
            >
              <div class="fl footer-top-mL">
                <div class="footer-top_location">快速联系</div>
                <div class="footer-top_info">
                  <p>电话：010-88192088</p>
                  <p>邮箱：mail@cisinfo.cn</p>
                  <p>地址：北京市海淀区中关村南街天作国际B座6层</p>
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
            >
              <div class="fl footer-top-mL">
                <div class="footer-top_location">产品与服务</div>
                <div class="footer-top_info">
                  <p @click="handleClick('中智工业智能MOM套件')">中智工业智能MOM套件</p>
                  <p @click="handleClick('中智工业生产管控软件')">中智工业生产管控软件</p>
                  <p @click="handleClick('中智工业集成优化软件')">中智工业集成优化软件</p>
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
            >
              <div class="fl footer-top-mL">
                <div class="footer-top_location">行业与方案</div>
                <div class="footer-top_info">
                  <p @click="handleTo('/industry')">智慧工厂库区管理解决方案</p>
                  <p @click="handleTo('/industry')">能源装置节能优化解决方案</p>
                  <p @click="handleTo('/industry')">机加工行业智能制造解决方案</p>
                  <p @click="handleTo('/industry')">区域级工业互联网解决方案</p>
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
            >
              <div class="fl footer-top-mL">
                <div class="footer-top_location">客户案例</div>
                <div class="footer-top_info">
<!--                  <p @click="handleTo('/cloud')">中海油气电集团生产运营协调管理系统</p>-->
                  <p @click="handleTo('/cloud')">内蒙古鄂尔多斯电冶集团MES项目</p>
                  <p @click="handleTo('/cloud')">中石油西北销售油库安全运行管理系统</p>
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="4"
              :lg="4"
              :xl="4"
            >
              <div class="fl footer-top-mL footer-top_weweima">
                <!-- <img :src="bottomCode" alt=""> -->
                <div class="footer-top_location">关注微信</div>
                <div class="footer-top_info  clearfix">
                  <el-image
                    fit="contain"
                    :src="require('../assets/erweima.png')"
                    style="width:100px;"
                  ></el-image>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="footer-bottom">
        <p>©2021 北京中智软创信息技术有限公司 版权所有 www.cisinfo.cn | 总机 010-88192088 | <a
            style="cursor: pointer;color: orange;"
            href="https://beian.miit.gov.cn/"
            target="_blank"
          >京ICP备15032807号-1</a></p>
      </div>
    </div>

    <el-dialog
      title="方案咨询"
      :visible.sync="dialogFormVisible"
      width="520px"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item
          label="电子邮箱"
          prop="Email"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.Email"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="电话"
          prop="Telephone"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.Telephone"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司名称"
          prop="Company"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.Company"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="Industry"
          label="您的留言"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.Industry"
            size="mini"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="dialogFormVisible = false"
        >取 消</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="handleSubmit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input3: '',
      dialogFormVisible: false,
      form: {
        Company: '',
        Industry: '',
        Telephone: '',
        Email: '',
      },
      formLabelWidth: '110px',
      rules: {
        Email: [
          { required: true, message: '请填写邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        Telephone: [
          { pattern:/^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的电话号码', trigger: 'blur' }
        ],
        Company: [
          { required: true, message: '请填写公司', trigger: 'blur' }
        ],
        Industry: [
          { required: true, message: '请填写留言', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // this.bottomCode = require('common/img/icon-erweima.png')
  },
  methods: {
    handleTo(item) {
      this.$router.push({ path: item });
    },
    handleClick(item) {
      this.$router.push({ path: '/production', query: { key: item } });
      localStorage.setItem('CISPROINFO', item)
    },
    handleIn() {
      this.dialogFormVisible = true
    },
    handleSubmit() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          let res = await fetch('http://101.201.141.88:8060/api/Message/SendInformation', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.form),
          })
          console.log(res.status)
          if (res.status == 200) {
            this.$message({
              duration: 500,
              message: '发送邮件成功',
              type: 'success',
              onClose: () => {
                this.dialogFormVisible = false
              }
            });

          } else {
            this.$message.error('发送邮件失败，请重新发送');
          }
          // if(res.)
        } else {
          console.log('error submit!!');
          return false;
        }
      });


      // if(res){

      // }
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: #060606;
  position: relative;
  .email {
    width: 850px;
    height: 63px;
    background: #0d13d7;
    border-radius: 8px;
    padding: 30px;
    color: #ffffff;
    margin-left: -425px;
    margin-right: auto;
    top: -20px;
    left: 50%;
    position: absolute;
    .title {
      font-size: 1.25em;
    }
    .desc {
      font-size: 0.625em;
      margin-top: 8px;
    }
    .input {
      border-radius: 30px;
      padding: 15px;
      background-color: #ffffff;
    }
  }
  .footer-top {
    color: #ffffff;
    .footer-content {
      .footer-top_location {
        font-weight: bold;
        margin-bottom: 1em;
        margin-top: 1.5em;
      }
      .footer-top_info {
        p {
          line-height: 2em;
          color: #8a8a8a;
        }
      }
    }
  }
  .footer-top-mL {
    p {
      cursor: pointer;
    }
  }
  .footer-bottom {
    text-align: center;
    font-size: 12px;
    color: #8a8a8a;
  }

  .logo {
    width: 200px;
    margin-top: 35px;
  }
}

@media (min-width: 1100px) {
  .logo {
    width: 80px;
  }
}
@media screen and (min-width: 980px) {
  .footer-top {
    padding: 160px 20px 0 20px;
    .footer-content {
      .footer-top_location {
        font-size: 0.9375em;
      }
      .footer-top_info {
        p {
          font-size: 0.8125em;
        }
      }
    }
  }
  .footer-bottom {
    text-align: center;
    font-size: 12px;
    color: #8a8a8a;
    line-height: 1.5em;
    padding: 20px;
    width: 100%;
    margin-top: 25px;
  }
  .el-col-md-4,
  .el-col-lg-4,
  .el-col-xl-4 {
    width: 20% !important;
    padding-left: 50px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 980px) {
  .footer-top {
    padding: 20px;
    .footer-content {
      .footer-top_location {
        font-size: 1.2em;
      }
      .footer-top_info {
        p {
          font-size: 1em;
          line-height: 2em;
        }
      }
    }
  }
  .footer-bottom {
    text-align: center;
    font-size: 12px;
    line-height: 1.5em;
    padding: 20px;
  }
  .el-col-24,
  .el-col-xs-24 {
    width: 100% !important;
    padding-left: 10px !important;
  }
}
</style>
