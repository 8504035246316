<template>
  <div id="app">
    <header-cis></header-cis>
    <transition name="el-fade-in-linear">
      <router-view />
    </transition>
    <footer-cis></footer-cis>
  </div>

</template>

<script>
import HeaderCis from '@/components/header'
import FooterCis from '@/components/footer'

export default {
  name: 'App',
  components: {
    HeaderCis,
    FooterCis
  },
  mounted() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {

               //跳转移动端页面
               this.$store.state.isPhone = true
           } else {
               //跳转pc端页面
               this.$store.state.isPhone = false
           }
  }
}
</script>

<style>
/* @import "./common/css/default.css";
@import "./common/css/public.css"; */

body {
  font-size: 14px;
  font-family: "Microsoft YaHei", "sans-serif", "Tahoma", "Helvetica";
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#app {
  position: relative;
  background: #fff;
  overflow: hidden;
}
</style>
