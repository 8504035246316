import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import '@/styles/index.scss'
import animated from 'animate.css'
import HTitle from '@/components/HTitle'
import HBanner from '@/components/HBanner'
import store from '@/store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper.scss'

// import 'swiper/css/swiper.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'

// Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Vue.use(animated);
Vue.use(HTitle);
Vue.use(HBanner);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */);
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
