<template>
  <div class="banner">
    <el-image
      fit="cover"
      :src="bg"
      style="width:100%;height:100%"
    >
    </el-image>
    <div class="content-container">
      <div class="content">
      <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HBanner',
  props: ['bg']
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 450px;
  position: relative;
  .content-container {
    position: absolute;
    top: 45px;
    left: 0;
    bottom: 0;
    right: 0;
    .content {
      
      margin-left: auto;
      margin-right: auto;
      >div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 980px) {
  .banner{
    height: 450px;
    .content{
      width: 960px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 980px) {
  .banner{
    height: 320px;
  }
}
</style>