<template>
  <div class="h-title">{{msg}}</div>
</template>

<script>
export default {
  name: 'HTitle',
  props: ['msg']
}
</script>

<style lang="scss" scoped>
.h-title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 980px) {
  .h-title {
    text-align: center;
    font-size: 2em;
    padding-top: 55px;
    padding-bottom: 65px;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 980px) {
  .h-title {
    text-align: center;
    font-size: 1.5em;
    padding-top: 35px;
    padding-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
  }
}
</style>