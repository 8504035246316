<template>
  <div>
    <div
      class="header-top"
      :style="`background-color: rgba(0,0,0,${alpha});`"
      ref="header-top"
    >
      <div class="header-content">
        <div
          class="header-left"
          @click="handleClick('/home')"
        >
          <img
            src="../assets/logo.png"
            alt=""
          >
        </div>
        <div
          class="header-middle"
          style="width:750px"
          v-if="!$store.state.isPhone"
        >
          <el-row>
            <el-col :span="4">
              <el-button
                type="text"
                style="color:#ffffff"
                @click="handleClick('home')"
              >首页</el-button>
            </el-col>
            <el-col :span="4">
              <el-popover
                trigger="hover"
                placement="bottom-start"
                :visible-arrow="false"
                :width="950"
              >
                <el-row>
                  <el-col
                    :span="item.key===1?12:6"
                    v-for="(item,i) in productList"
                    :key="i"
                  >
                    <div class="clearfix">
                      <span class="pro_plat_title">{{item.title}}</span>
                    </div>
                    <el-divider></el-divider>
                    <div
                      class="pro_content"
                      :style="`display:flex;flex-direction:row;flex-wrap:wrap`"
                    >
                      <div
                        v-for="(item1,i1) in item.list"
                        :key="i1"
                        class="pro_item"
                        :style="`${item.key===1?'width:38%':'width:90%'}`"
                      >
                        <div
                          style="cursor: pointer;"
                          @click="handleTo(item1)"
                        >
                          <div class="nav-title">{{item1.title}}</div>
                          <!-- <div class="nav-desc">{{item1.desc}}</div> -->
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-button
                  type="text"
                  slot="reference"
                  style="color:#ffffff"
                  @click="handleClick('/pro')"
                >产品与服务</el-button>
              </el-popover>
            </el-col>
            <el-col :span="4">
              <el-button
                type="text"
                style="color:#ffffff"
                @click="handleClick('/industry')"
              >行业与方案</el-button>
            </el-col>
            <el-col :span="4">
              <el-button
                type="text"
                style="color:#ffffff"
                @click="handleClick('/cloud')"
              >客户案例</el-button>
            </el-col>
            <el-col :span="4">
              <el-button
                type="text"
                style="color:#ffffff"
                @click="handleClick('about')"
              >关于中智</el-button>
            </el-col>
            <!-- 添加服务热线 -->
            <el-col :span="4">
              <el-button
                  type="text"
                  style="color:#ffffff"
              >服务热线 400-6168695</el-button>
            </el-col>
          </el-row>
        </div>

        <div
          v-else
          @click="menu=!menu"
        >
          <i
            class="el-icon-menu"
            style="color:#ffffff;font-size:2em;"
          ></i>
        </div>
        <!-- <div v-if="!$store.state.isPhone">

        </div> -->

      </div>
    </div>

    <el-drawer
      title=""
      :visible.sync="menu"
      direction="ltr"
      size="80%"
    >
      <div class="menu-s">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          background-color="#000"
          text-color="#fff"
        >
          <el-menu-item
            index="2"
            @click="handleClick('home')"
          >
            <el-button
              type="text"
              style="color:#ffffff"
            >首页</el-button>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <el-button
                type="text"
                style="color:#ffffff"
                @click="handleClick('/pro')"
              >产品与服务</el-button>
            </template>
            <el-menu-item-group
              v-for="(item) in productList"
              :key="item.title"
            >
              <el-submenu :index="item.title">
                <template slot="title">{{item.title}}</template>
                <el-menu-item
                  :index="item1.title"
                  v-for="(item1) in item.list"
                  :key="item1.title"
                  @click="handleTo(item1)"
                >{{item1.title}}</el-menu-item>
              </el-submenu>

            </el-menu-item-group>

          </el-submenu>
          <el-menu-item
            index="2"
            @click="handleClick('/industry')"
          >
            <el-button
              type="text"
              style="color:#ffffff"
            >行业与方案</el-button>
          </el-menu-item>
          <el-menu-item
            index="3"
            @click="handleClick('/cloud')"
          >
            <el-button
              type="text"
              style="color:#ffffff"
            >客户案例</el-button>
          </el-menu-item>
          <el-menu-item
            index="4"
            @click="handleClick('about')"
          >
            <el-button
              type="text"
              style="color:#ffffff"
            >关于中智</el-button>
          </el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>


export default {
  name: "home-header",
  data() {
    return {
      state: false,
      activeIndex2: "1",
      alpha: 0.1,
      menu: false,
      productList: [
        {
          key: 0,
          title: '中智工业智能MOM套件',
          list: [
            { list: ['化工批次管理', '配煤数据校正', '实时数据库', '计划管理', '调度管理', '工艺管理', '操作管理', '设备管理', '计量管理', '物料管理', '质量管理', '三剂管理', '能源管理', '生产绩效管理'], title: '炼油行业MOM系统套件', desc: '基于微服务的新一代智慧MOM产品' },
            { list: ['化工批次管理', '实时数据库', '计划管理', '调度管理', '工艺管理', '操作管理', '设备管理', '计量管理', '物料管理', '质量管理', '三剂管理', '能源管理', '质量管理', '生产绩效管理', '安全环保管理', '统计报表'], title: '石油化工行业MOM系统套件', desc: '石油化工行业精准数字化把控' },
            { list: ['化工批次管理', '配煤数据校正', '实时数据库', '计划管理', '调度管理', '工艺管理', '操作管理', '设备管理', '计量管理', '物料管理', '质量管理', '三剂管理', '能源管理', '生产绩效管理', '安全环保管理', '统计报表'], title: '煤化工行业MOM系统套件', desc: '快速实现设备信息化采集' },
            { list: ['客户管理', '合同管理', '船货计划', '气量计划', '气量调度', '气量监测', '库存监测', '管存计算', '气量平衡', '气量交接', '槽车计划', '能耗管理', '设备检维修', '异常管理', '绩效管理', '统计报表', '调度指挥', '生产成本管理', '移动应用'], title: 'LNG行业MOM系统套件', desc: '快速实现设备信息化采集' },
            { list: ['营销管理', '计划管理', '生产管理', '物料管理', '质量管理', '异常管理', '设备管理', '工艺技术管理', '生产看板', 'Andon管理', '刀具管理', '异常管理'], title: '机加工行业MOM系统套件', desc: '快速实现供、销数据智能可视化' },
            { list: ['流程监控', '安环监控', '计划管理', '调度管理', '操作管理', '物料管理', '能源管理', '仓库管理', '配方管理', '批次管理', '质量管理', '生产统计', '移动应用'], title: 'PVC行业MOM系统套件', desc: '通过操作优化企业高附加值产品收率' },
            { list: ['计划排产管理', '车间作业管理', '质量管理', '设备管理', '模具管理', '物流仓储管理', '生产看板', '生产预警', '能耗分析', '统计报表', '移动应用'], title: '注塑行业MOM系统套件', desc: '通过操作优化企业高附加值产品收率' },
            // {
            //   title: '智慧IMOO炼化工厂',
            //   desc: '采用了物联网、实时感知、海量信息处理、智能控制等技术,订单信息从ERP-IC卡-WMS-WCS自动传递,即通过RFIDIC卡,自动读取入库和出库信息,产品自动入库、出库,无人操作,全自动控制;承运商通过在线预约,自动排队, 短信提醒,即可自动提货;立体库会根据订单自行补货,自动灌装、封边、传输、码垛和装车,且自动清点盘库。',
            // },
          ]
        },
        {
          key: 1,
          title: '中智工业生产管控软件',
          list: [
            { list: ['实时数据采集', '数据压缩和存储', '数据缓存和断点续传', '工艺流程图监测', '工艺数据报警', '工艺数据回放', '工艺数据分析', '设备状态监测', '安全环保监测', '能源管网监测', '工艺台账', '统计报表'], title: '实时数据库软件', desc: '基于微服务的新一代智慧MOM产品' },
            { list: ['计量体系管理', '计量点管理', '装置加工计量', '罐区收付计量', '能源产耗计量', '物料进出厂计量', '计量仪表监测', '计量器具管理', '计量人员管理', '计量统计报表'], title: '计量管理软件', desc: '实现生产过程实时监控' },
            { list: ['设备台账管理', '设备档案管理', '检维修计划管理', '检维修工单管理', '故障管理', '设备启停管理', '设备运行管理', '润滑管理', '密封管理', '计量器具管理', '备品备件管理', '统计报表与绩效分析'], title: '设备全生命周期管理软件', desc: '能快速上手的工艺操作系统' },
            { list: ['物料主数据管理', '装置计量模型', '进出厂计量点管理', '自动装车系统集成', '地磅称重系统集成', '仓储系统集成', '进出厂计量', '装置投入产出计量', '罐区计量', '三剂/辅料计量', '物料移动管理', '物料平衡管理', '平衡报表'], title: '物料管理软件', desc: '实现提升物资流转的执行效率' },
            { list: ['能源主数据管理', '能源计量点管理', '能耗设备管理', '能源计划管理', '临时用能管理', '能源数据采集', '能源数据审核', '能源数据平衡', '能耗对标管理', '能源产耗综合分析', '国家、省市能耗监测平台集成'], title: '能源管理软件', desc: '实现企业业务精细化管理' },
            { list: ['综合展示', '生产运行监测', '消防/安防监测', '报警管理', '设备运行状态监测', '管线运行状态监测', '储罐运行监测', '公路装车过程监测', '问题管理', '绩效考评', '收发油分析', '大数据分析'], title: '油库智能安全生产管控软件', desc: '实现降本增效提升企业市场竞争力' },
            { list: ['指标设置', '公式设计', '数据集成', '指标计算', '指标考核', '指标分析', '指标改进',], title: '生产绩效管理软件', desc: '实现生产过程实时监控' },
            { list: ['频次样品管理', '临时采样管理', '数据采集和录入', '样品审核', '数据发布', '检验过程跟踪', '质量数据分析', '留样管理', '人员管理', '试剂管理', '库存管理', '设备管理', '通知提醒', '投诉管理', '仪器集成', '数据修约', '检验标准', '广播提醒'], title: '质量管理软件', desc: '实现提升物资流转的执行效率' },
            { list: ['工艺档案管理', '工艺变更管理', '工艺指标库', '工艺操作监控报警', '工艺操作规程', '操作日志', '工艺台账', '装置开停工管理', '工艺偏差统计', '操作平稳率', '装置自控率', '联锁投用率', '操作绩效分析'], title: '工艺操作管理软件', desc: '实现企业业务精细化管理' },
            { list: ['新产品研发管理', '配方管理', '工艺指标管理', '生产订单管理', '生产计划排程', '生产过程监测', '原料批次管理', '生产批次管理', '包装批次管理', '产品出厂管理', '客户投诉管理', '客户需求管理', '质量追溯管理'], title: '化工批次管理软件', desc: '实现降本增效提升企业市场竞争力' },
          ]
        },
        {
          key: 2,
          title: '中智工业集成优化软件',
          list: [
            { title: '复杂精馏装置实时优化', desc: '基于微服务的新一代智慧MOM产品' },
            { title: '蒸汽动力系统实时优化', desc: '能快速上手的工艺操作系统' },
            { title: '氢气系统实时优化', desc: '实现生产过程实时监控' },
          ]
        },
      ],
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.alpha = scrollTop / 500 > 1 ? 1 : scrollTop / 500
    })
  },
  methods: {
    handleSelect() { },
    handleClick(item) {
      this.$router.push({ path: item, });
      this.menu = false
    },
    handleTo(val) {
      localStorage.setItem('CISNEWSINFO', val.title)
      this.$router.push({ path: 'production-info', query: { key: val.title } })
      this.menu = false
    }
  }

}
</script>

<style lang="scss" scoped >
.header-top {
  height: 72px;
  width: 100%;
  position: fixed;
  z-index: 999;
  .header-content {
    width: 82.5%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    line-height: 72px;
    justify-content: space-between;
    .header-left {
      cursor: pointer;
      img {
        width: 180px;
      }
    }
  }
}
.nav-title {
  font-size: 1em;
  line-height: 3.2em;
  color: #ffffff;
}
.nav-desc {
  font-size: 0.625em;
  line-height: 2em;
  margin-bottom: 1em;
}
.pro_content {
  .pro_item {
    margin-right: 15px;
    .pro_item_title {
      color: #999999;
      line-height: 2em;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
      span {
        width: 4px;
        height: 4px;
        display: inline-block;
        font-size: 0.825em;
        border-radius: 2px;
        background-color: #264bf8;
        margin-right: 6px;
      }
    }
  }
}
.pro_plat_title {
  font-size: 1.25em;
  color: #ffffff;
}

.menu-s {
  height: 100%;
  overflow-y: scroll;
}
</style>
